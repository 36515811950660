<template>
  <q-tr
    :props="data"
    class="clickable"
    :style="isLockedDR && 'opacity: 0.7;'"
    @dblclick="handleDBClick"
  >
    <td class="q-td text-left">
      <q-checkbox
          size="xl"
          :model-value="!!items.find(({id}) => id === data.row.id)"
          color="amber-6"
          @click="handleCheck(data.row)"
      />
    </td>

    <q-td
      key="number"
      :props="data"
      auto-width
    >
      {{ data.rowIndex +  1 }}
    </q-td>

    <q-td
      key="id"
      :props="data"
    >
      <div class="row items-center">
        <h5 class="text-subtitle1 q-ma-none">
          {{ data.row.id }}
        </h5>

        <q-icon
          :name="isLockedDR ? 'lock' : 'lock_open'"
          :color="isLockedDR ? 'negative' : 'positive'"
        />
      </div>

      <h6 class="q-my-none text-subtitle2">
        <strong>{{ data.row.clientExtId || data.row.extId }}</strong>
      </h6>

      <h6 v-if="data.row.eav && data.row.eav['delivery-request-products-order']" class="q-my-none text-caption">
        <strong>{{ `${$t('Order ID')}: ${data.row.eav['delivery-request-products-order']}` }}</strong>
      </h6>

      <p class="q-ma-none text-caption">
        {{ $moment(typeof data.row.created === 'object' ? data.row.created.date : data.row.created).format(appOptions.formats.fullDate) }}
      </p>

      <h6 v-if="data.row.createdByDocument" class="q-my-none text-caption">
        <strong>{{`${$t('CFD ID')}: ${data.row.createdByDocument}`}}</strong>
        <q-tooltip>{{'Carton forwarding document ID'}}</q-tooltip>
      </h6>
    </q-td>

    <q-td
      key="customer"
      :props="data"
      class="text-caption"
      style="max-width: 200px; word-wrap: break-word; white-space: normal !important;"
    >
      <p
        v-if="data.row.recipientName"
        class="q-ma-none"
      >
        {{ data.row.recipientName }}
      </p>

      <p class="q-ma-none">
        {{ data.row.recipientPhone }}
      </p>

      <p
        v-if="data.row._embedded.recipientLocality"
        class="q-ma-none"
      >
        <span
          v-if="data.row._embedded.recipientLocality && data.row._embedded.recipientLocality._embedded && loadCountryByLink && loadCountryByLink.code"
          :class="'flag-icon flag-icon-' + loadCountryByLink.code.toLowerCase()"
        />

        <span v-if="data.row._embedded.recipientLocality.postcode">
          {{ data.row._embedded.recipientLocality.postcode }},
        </span>

        {{ data.row._embedded.recipientLocality.name }}
      </p>

      <q-token v-if="data.row.recipientComment">
        {{ data.row.recipientComment }}
      </q-token>
    </q-td>

    <q-td
      key="sender"
      :props="data"
    >
      <h6
        v-if="data.row._embedded.sender"
        class="q-ma-xs text-caption"
      >
        {{ data.row._embedded.sender.name }}
      </h6>

      <p v-else>
        --
      </p>
    </q-td>

    <q-td
      key="state"
      :props="data"
    >
      <div style="max-width: 200px;">
        <super-dropdown :model="drDropdownModel" />
        <q-tooltip v-if="data.row.status && data.row.status.length > 0">
            <template v-slot:default>
                <div>
                    <div v-for="(err, i) in data.row.status" :key="i" class="text-caption">
                        <q-item-section>{{ err.message }}</q-item-section>
                    </div>
                </div>
            </template>
        </q-tooltip>
      </div>

    </q-td>

    <q-td
      key="shipping"
      :props="data"
    >
      <img
        v-if="data.row._embedded.deliveryService && data.row._embedded.deliveryService.logo"
        :src="checkLogo(data.row._embedded.deliveryService)"
        :title="data.row._embedded.deliveryService.name"
        style="width: 80px; height: 50px; object-fit: contain;"
      >

      <h6
        v-if="data.row._embedded.deliveryService && !data.row._embedded.deliveryService.logo"
        class="q-my-none"
      >
        {{ data.row._embedded.deliveryService.name }}
      </h6>

      <h5
        v-if="data.row.trackingNumber"
        class="q-ma-none text-subtitle1"
      >
        <span
          v-if="data.row.trackingNumber"
          @click="doCopy(data.row.trackingNumber)"
          class="doc-link cursor-pointer q-mb-md"
        >
          #{{ data.row.trackingNumber }}
          <q-icon name="content_copy" class="icon-top-right" size="xs"/>
        </span>
      </h5>

      <ul class="q-pa-none q-ma-none">
        <li class="q-mb-xs">
          <span>{{ $t('Delivery price') + ': ' }}</span>

          <q-badge
            class="q-pa-xs"
            color="secondary"
          >
            {{ (data.row.retailPrice || 0) + ' ' }}
            <span v-if="data.row._embedded.currency">{{ data.row._embedded.currency.symbol }}</span>
          </q-badge>
        </li>

        <li class="q-mb-xs">
            <span>{{ $t('Delivery by rate price') + ': ' }}</span>

            <q-badge
                class="q-pa-xs"
                :color="paymentStateColors[data.row.paymentState]"
            >
                <span>{{ (data.row.price || 0) + ' '  }}</span>

                <span v-if="hasDeliveryRequestCurrency">
                  {{data.row._embedded.deliveryRequest._embedded.currency.symbol }}
              </span>
            </q-badge>
        </li>
      </ul>
    </q-td>

    <q-td
      key="actions"
      style="max-width: 100px"
      :props="data"
    >
      <q-btn-dropdown
        class="q-ml-sm"
        size="lg"
        flat round dense
        no-icon
        dropdown-icon="more_vert"
      >
        <q-list>
          <q-item clickable v-close-popup :disable="isBarcodePrintButtonEnabled">
            <q-item-section @click="handlePrint">
              <q-item-label>
                {{ $t('Print label') }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </q-td>
  </q-tr>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Helpers
import { getStateColors } from '../../helpers/helpers'

// Components
import SuperDropdown from '../super-dropdown/SuperDropdown'

// Utils
import { doCopy } from './../../utils/copy-utils'

// Configs
import deliveryRequestStatusMatrix from './../../config/DeliveryRequestMatrix'

export default {
  name: 'DeliveryRequestRowTable',
  emits: ['dbclick', 'check'],
  components: {
    SuperDropdown
  },
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    },
    data: {
      type: Object,
      required: true
    },
    row: {
      type: Object,
      default () {
        return {}
      }
    },
    column: {
      type: Object,
      default () {
        return {}
      }
    },
    countries: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      drStatuses: deliveryRequestStatusMatrix,
      stateColors: getStateColors(deliveryRequestStatusMatrix),
      paymentStateColors: {
        missing: 'default',
        payment_waiting: 'warning',
        received: 'info',
        partly_payed: 'warning',
        payed: 'success'
      },
      drStatusesForPrint: ['pending_queued', 'cancelled', 'pending','pending_error','error','delete', 'pre_processing'],
    }
  },
  computed: {
    ...mapGetters([
      'lockedDeliveryRequests',
      'appOptions'
    ]),
    isBarcodePrintButtonEnabled () {
      if(this.drStatusesForPrint.includes(this.data.row.state)) {
        return true
      }
      return false
    },
    isLockedDR () {
      return this.lockedDeliveryRequests.includes(this.row.id)
    },
    loadCountryByLink () {
      const id = this.baseName(this.row._embedded.recipientLocality._embedded.country._links.self.href)

      return this.countries[id]
    },
    drDropdownModel () {
      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: 'default',
        disabledLabels: true,
        editableFields: false,
        onClick: (state) => {
          this.saveDeliveryRequestByPayload({ id: this.row.id, data: { state } })
            .then(item => {
              this.setDeliveryRequestFromList(item)
            })
        },
        value: { id: 'pending_queued', title: 'Pending Queued' },
        options: this.drStatuses
      }

      if (this.row.state !== option.value.id) {
        this.drStatuses.find(group => {
          let status = group.buttons.find(btn => {
            return btn.id === this.row.state
          })

          if (status) {
            option.color = group.color
            option.value = status
          }

          return status
        })
      }

      return option
    }
  },
  methods: {
    ...mapMutations([
      'setDeliveryRequestFromList'
    ]),
    ...mapActions([
      'saveDeliveryRequestByPayload',
      'printLabelByRequestByID',
      'printLabelByRequestBySize',
    ]),
    handleCheck () {
      this.$emit('check', this.data.row)
    },
    handlePrint () {
        this.printLabelByRequestByID({ deliveryRequest: this.data.row })
    },
    handleDBClick () {
      this.$emit('dbclick', this.data.row)
    },
    doCopy (content) {
      return doCopy(content)
    },
    baseName (str) {
      let base = str.substring(str.lastIndexOf('/') + 1)

      if (base.lastIndexOf('.') !== -1) {
        base = base.substring(0, base.lastIndexOf('.'))
      }

      return base
    },
    checkLogo (service) {
      if ((service || {}).logo.includes('http')) {
        return (service || {}).logo
      } else {
        return this.appOptions.defaultServer + service.logo
      }
    }
  }
}
</script>
